import { ReactComponent as taskSvgComponent } from '../../../../assets/images/planner-create-overlay-task-icon.svg';
import { ReactComponent as eventSvgComponent } from '../../../../assets/images/planner-create-overlay-event-icon.svg';
import { ReactComponent as workBlockSvgComponent } from '../../../../assets/images/planner-create-overlay-work-block-icon.svg';
import { useTranslation } from 'react-i18next';
import PlusBtnOverlaySingleOption from './PlusBtnOverlaySingleOption';
import './PlusButtonOverlay.scss';

const PlusButtonOverlay = () => {
  const { t } = useTranslation();

  return (
    <div className="planner-plus-overlay">
      <PlusBtnOverlaySingleOption type="task" SvgComponent={taskSvgComponent} />
      <PlusBtnOverlaySingleOption type="event" SvgComponent={eventSvgComponent} />
      <PlusBtnOverlaySingleOption type="workBlock" SvgComponent={workBlockSvgComponent} />
    </div>
  );
};

export default PlusButtonOverlay;
