import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import {
  ApplicationInsights,
  DistributedTracingModes,
  SeverityLevel,
} from '@microsoft/applicationinsights-web';
import { chatSessionIdLocalStorageKey } from './app/constants';
import { getItemFromLocalStorage } from './shared/utils/localStorage.utils';
import type { EnhancedStore } from '@reduxjs/toolkit';
import type { RootState } from './app/store';

export var appInsights: ApplicationInsights;
export var reactPlugin: ReactPlugin;
export var clickPlugin: ClickAnalyticsPlugin;

let isUserContextSet = false;

/**
 * Injecting the store to be able to use it inside axios interceptors
 * https://redux.js.org/faq/code-structure#how-can-i-use-the-redux-store-in-non-component-files
 */

// eslint-disable-next-line
let store: EnhancedStore<RootState> | undefined;
export const injectStoreApplicationInsights = (_store: EnhancedStore) => {
  store = _store;
};

export const initAppInsights = () => {
  if (process.env.REACT_APP_APPLICATIONINSIGHTS_CONNECTION_STRING) {
    reactPlugin = new ReactPlugin();
    clickPlugin = new ClickAnalyticsPlugin();
    appInsights = new ApplicationInsights({
      config: {
        connectionString: process.env.REACT_APP_APPLICATIONINSIGHTS_CONNECTION_STRING,
        enableAutoRouteTracking: true,
        distributedTracingMode: DistributedTracingModes.AI_AND_W3C,
        enableCorsCorrelation: true,
        extensions: [reactPlugin, clickPlugin],
        extensionConfig: {
          [clickPlugin.identifier]: {
            autoCapture: true,
            dataTags: { useDefaultContentNameOrId: true },
          },
        },
        disableCookiesUsage: true,
      },
    });

    appInsights.loadAppInsights();
  }
};

export abstract class ApplicationInsightsApi {
  static trackException(error: any): void {
    try {
      appInsights?.trackException(
        {
          exception: new Error(
            typeof error === 'string' ? error : error?.message || JSON.stringify(error),
          ),
          severityLevel: SeverityLevel.Error,
        },
        getUserContext(),
      );
    } catch (error: any) {
      appInsights?.trackException({
        exception: new Error(
          typeof error === 'string'
            ? error
            : error?.message || error?.error?.message || 'error message not available',
        ),
        severityLevel: SeverityLevel.Error,
      });
    }
  }

  static trackEvent(name: string, event?: { [key: string]: any }): void {
    try {
      appInsights?.trackEvent({ name, properties: event }, getUserContext());
    } catch (error: any) {
      appInsights?.trackException({
        exception: new Error(
          error?.message || error?.error?.message || 'error message not available',
        ),
        severityLevel: SeverityLevel.Error,
      });
    }
  }

  static trackTrace(message: string, additionalData?: { [key: string]: any }): void {
    try {
      appInsights?.trackTrace({ message, properties: additionalData }, getUserContext());
    } catch (error: any) {
      appInsights?.trackException({
        exception: new Error(
          error?.message || error?.error?.message || 'error message not available',
        ),
        severityLevel: SeverityLevel.Error,
      });
    }
  }
}

function getUserContext() {
  const user = store?.getState()?.userReducer?.user;
  return {
    userGroup: user?.signUpType || '',
    userId: user?.id || '',
    sessionId: getItemFromLocalStorage<string>(chatSessionIdLocalStorageKey) || '',
  };
}

// https://learn.microsoft.com/en-us/azure/azure-monitor/app/api-custom-events-metrics#authenticated-users
export function setAppInsightsUserContext() {
  try {
    if (!isUserContextSet) {
      const user = store?.getState()?.userReducer?.user;
      if (appInsights && user) {
        // must not include spaces or any of the characters ,;=|
        const validUserId = user.id.replace(/[,;=| ]+/g, '_');
        appInsights.setAuthenticatedUserContext(validUserId, undefined, true);
        isUserContextSet = true;
      }
    }
  } catch (error) {
    ApplicationInsightsApi.trackException(error);
  }
}

export enum ELogLabels {
  SELECT = 'Select',
}
