import { store } from '../../../../app/store';
import { getWorkBlockAssignedTasksSorted, isDesktopView } from '../../../../shared/utils/utils';
import type { IMessageDataTask } from '../../../chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.interface';
import {
  ETaskSource,
  ETaskStatus,
} from '../../../chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.interface';
import type { ICalendarEvent } from './CalendarDay';
import { defaultHourHeightDesktop, defaultHourHeightMobile } from './CalendarDay';
import { addMinutes } from 'date-fns';
import SassVariables from '../../../../styles/style.module.scss';
import { selectedPlanViewTypeLocalStorageKey } from '../../../../app/constants';
import type { MouseEvent } from 'react';
import { ECalendarEventDurationTypeClassName } from './CalendarDay.interfaces';
import { EPlanViewSelection } from '../../../chat-wrapper/resizable-container/stage-container/stage-planner/stagePlanner.interface';

export const defaultDuration = 30; // Default duration in minutes

export const getDurationTypeClassName = (
  duration: number | null | undefined,
): ECalendarEventDurationTypeClassName => {
  const shorterThen30ClassName = ECalendarEventDurationTypeClassName.SHORTER_THAN_30_DURATION;
  const over30ClassName = ECalendarEventDurationTypeClassName.OVER_30_DURATION;
  const desktopThreshold = 60; // in minutes
  const mobileThreshold = 30; // in minutes
  if (!duration) return shorterThen30ClassName;
  if (isDesktopView()) {
    return duration / 60 > desktopThreshold ? over30ClassName : shorterThen30ClassName;
  }
  return duration / 60 > mobileThreshold ? over30ClassName : shorterThen30ClassName;
};

export function convertSingleItemToCalendarEvent(
  el: IMessageDataTask,
  hourHeight?: number,
  startingHour?: number,
): ICalendarEvent {
  hourHeight = hourHeight || (isDesktopView() ? defaultHourHeightDesktop : defaultHourHeightMobile);
  startingHour = startingHour || 0;

  const calcDuration = el.duration && el.duration / 60 > 15 ? el.duration / 60 : defaultDuration;
  const isWorkBlock = el.isEvent && el.isWorkBlock;
  const start = new Date(el.workTime!);
  const end = addMinutes(start, calcDuration);

  const startHour = start.getHours();
  const startMinutes = start.getMinutes();
  const endHour = end.getHours();
  const endMinutes = end.getMinutes();

  const endAdjustedHour = endHour === 0 && endHour < startHour ? 24 : endHour; // Treat 12AM correctly
  const startOffset = startHour * hourHeight + (startMinutes * hourHeight) / 60;
  const endOffset = endAdjustedHour * hourHeight + (endMinutes * hourHeight) / 60;
  const top = startOffset - startingHour * hourHeight;
  const height = Math.abs(endOffset - startOffset);

  const allTasks = store.getState().StageTasksReducer.allTasks;
  const currentTaskPlacement = store.getState().StagePlannerReducer.currentTaskPlacement;

  // calc event background color
  let backgroundColor = SassVariables.planScheduledItemBackground; // default WB color
  if (!el.isWorkBlock) {
    if (el.isEvent && el?.source !== ETaskSource.Internal) {
      backgroundColor = SassVariables.Neutral3Color;
    } else if (el?.tags && el?.tags?.length > 0) {
      const firstTagColor = el.tags[0].color || SassVariables.whiteColor;
      backgroundColor =
        firstTagColor === SassVariables.transparent ? SassVariables.whiteColor : firstTagColor;
    } else backgroundColor = SassVariables.whiteColor;
  }

  const playViewType: EPlanViewSelection =
    (localStorage.getItem(selectedPlanViewTypeLocalStorageKey) as EPlanViewSelection | null) ||
    EPlanViewSelection.MY_WEEK;
  let titleFontSize = 12;
  if (playViewType === EPlanViewSelection.MY_DAY) titleFontSize += 2;

  return {
    id: el.id!,
    start,
    end,
    top,
    height,
    title: el?.name ? el.name : isWorkBlock ? 'Work Block' : '',
    titleFontSize: `${titleFontSize}px`,
    durationType: getDurationTypeClassName(el.duration),
    duration: el.duration,
    isWorkBlock,
    backgroundColor: backgroundColor,
    titleColor: SassVariables.appDarkColor,
    isRecurring: !!el.workTimeRecurrenceType,
    parentId: el.parentId,
    source: !isWorkBlock ? el?.source || ETaskSource.Internal : undefined,
    isEvent: isWorkBlock ? false : el.isEvent || false,
    workBlockId: el?.workBlockId || null,
    status: el?.status || ETaskStatus.NOT_STARTED,
    relatedTasks: isWorkBlock
      ? getWorkBlockAssignedTasksSorted(
          [...allTasks, currentTaskPlacement || ({} as IMessageDataTask)],
          el.id!,
          start,
        )
      : [],
  };
}

export type TResizingEventEdge = 'top' | 'bottom' | null;

export const getResizingEventEdge = (
  e: MouseEvent<HTMLElement>,
  cellHeight: number,
): TResizingEventEdge => {
  const edgeOffsetEventLessOrEqualCellHeight = 4; // Pixels from edge to detect resizing when event height equals cell height
  const edgeOffsetEventLargerCellHeight = 8; // Pixels from edge to detect resizing when event height differs from cell height
  const edgOffsetForResizing =
    e.currentTarget.offsetHeight <= cellHeight
      ? edgeOffsetEventLessOrEqualCellHeight
      : edgeOffsetEventLargerCellHeight;
  const rect = e.currentTarget.getBoundingClientRect();
  const isTop = e.clientY - rect.top < edgOffsetForResizing;
  const isBottom = rect.bottom - e.clientY < edgOffsetForResizing;
  return isTop ? 'top' : isBottom ? 'bottom' : null;
};

const cellInMinutes = 30; // Each cell is 30 minutes

// Calculate new duration in seconds by the new height
export const calcNewDuration = (newHeight: number, cellHeight: number) => {
  let newDuration = Math.round(newHeight / cellHeight) * cellInMinutes;
  newDuration *= 60; // Convert to seconds
  return newDuration;
};

// Calculate new start date
export const calcNewStartTime = (
  currentStart: Date,
  initialHeight: number,
  newHeight: number,
  cellHeight: number,
) => {
  const numberOfCellsUserMoved = (initialHeight - newHeight) / cellHeight;
  const minutesToAdd = numberOfCellsUserMoved * cellInMinutes;
  const newStartTime = addMinutes(currentStart, minutesToAdd);
  return newStartTime;
};

export const getCalendarEventIconColor = (eventColor?: string): string | undefined => {
  if (
    !eventColor ||
    eventColor === SassVariables.transparent ||
    eventColor === SassVariables.whiteColor
  ) {
    return undefined;
  }
  return eventColor;
};
