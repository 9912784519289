import type { FunctionComponent } from 'react';
import React, { Fragment } from 'react';
import { ReactComponent as BellIconComponent } from '../../../../../../../assets/images/task-bell-icon.svg';
import './SchedulingSection.scss';
import type { IRecurrenceValues, ISubTask, ITaskTag } from '../../stageTasks.interface';
import { ETaskStatus } from '../../stageTasks.interface';
import createAppOverlayPopover, {
  EAppOverlaySlideInMobileAnimation,
} from '../../../../../../../shared/components/app-overlay-popover/createAppOverlayPopover';
import { TaskEditOverlay } from '../../task-edit-overlays/TaskEditOverlay';
import {
  editOverlaysPositionStyle,
  getElementPosition,
  getElementWidthAndHeight,
} from '../CreateOrEditTask.utils';
import type { FieldArrayMethodProps } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../../../../../app/store';
import { useLocalStorage } from '../../../../../../../shared/utils/useLocalStorage';
import {
  chatSessionIdLocalStorageKey,
  subTaskOverlayPopoverClassName,
} from '../../../../../../../app/constants';
import {
  createTaskReqAction,
  getTasksListReqAction,
  updateTaskReqAction,
} from '../../stageTasks.store';
import { minutesToHoursAndMinutesStringRepresentation } from '../../../../../../../shared/utils/utils';
import {
  getDateFormatted,
  getTimeRange,
} from '../../../../../chat/chat-conversation/message-data-card/tasks-list/TasksList.utils';
import { uuid } from '../../../../../../../shared/utils/uuid';
import WorkBlockDataContent from './work-block-data-content/WorkBlockDataContent';
import { ECloseSwipeDirection } from '../../../../../../../shared/hooks/swipe-hooks/swipe.utils';

interface ISchedulingSectionProps {
  controlTitle?: string;
  title: string;
  formValue: string;
  SvgIconComponent: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  onClick: (e: React.MouseEvent<HTMLElement, any>) => void;
  task: ISubTask;
  isSubTask: boolean;
  multiWorkTimesSubTasks: ISubTask[] | null;
  setCalendarAsTimePicker?: (isMultipleWorkTimePicker?: boolean, taskId?: string) => void;
  appendWorkTimeToTask?: (
    value: ISubTask | ISubTask[],
    options?: FieldArrayMethodProps | undefined,
  ) => void;
  updateWorkTimeToTask?: (index: number, updatedTask: ISubTask) => void;
  removeWorkTimeToTask?: (index: number) => void;
  reminderType?: 'dueDate' | 'workTime';
  isReadOnly: boolean;
  isEvent?: boolean;
}

const SchedulingSection: FunctionComponent<ISchedulingSectionProps> = ({
  controlTitle,
  title,
  formValue,
  onClick,
  task,
  isSubTask,
  multiWorkTimesSubTasks,
  setCalendarAsTimePicker,
  appendWorkTimeToTask,
  updateWorkTimeToTask,
  removeWorkTimeToTask,
  reminderType,
  SvgIconComponent,
  isReadOnly,
  isEvent = false,
}) => {
  const { sessionResponse } = useAppSelector((store) => store.chatReducer);
  const [sessionIdLocalStorage] = useLocalStorage(chatSessionIdLocalStorageKey, '');
  const dispatch = useAppDispatch();

  const shouldDisplayReminderBell = (reminderValue: number | null | undefined) => {
    return reminderValue !== null && reminderValue !== undefined && reminderValue >= 0;
  };

  const shouldDisplayAddWorkTimeAction = () => {
    if (
      isEvent ||
      reminderType === 'dueDate' ||
      isSubTask ||
      (task?.name && task?.parentId) ||
      !task.taskId
    ) {
      return reminderType === 'workTime' ? !formValue && !task.workBlockId : !formValue;
    }
    return !task?.isMultiWorkTimeTask;
  };

  const renderContentForTask = (subTask: ISubTask) => {
    const reminderValue =
      reminderType === 'dueDate' ? subTask?.dueDateReminder : subTask?.workTimeReminder;
    const bellIconClassName = `task-detail-icon--right task-bell`;
    return (
      <Fragment key={subTask?.taskId || uuid()}>
        {reminderType === 'workTime' && !!subTask?.workBlockId ? (
          <WorkBlockDataContent
            workBlockId={subTask.workBlockId}
            workBlockInstance={subTask?.workBlockInstance || null}
            onClickTaskWorkBlock={
              subTask?.parentId && subTask.isMultiWorkTimeTask
                ? (e) => {
                    handleAddWorkTimePopOver(e, subTask);
                  }
                : onClick
            }
          />
        ) : (
          <div
            data-testid={
              reminderType === 'workTime'
                ? subTask?.parentId
                  ? 'multi-work-time-item'
                  : 'work-time-item'
                : 'due-item'
            }
            onClick={
              subTask?.parentId && reminderType === 'workTime' && subTask.isMultiWorkTimeTask
                ? (e) => {
                    handleAddWorkTimePopOver(e, subTask);
                  }
                : onClick
            }
            className={`scheduling-section-data-content-container ${
              reminderType === 'workTime' && subTask?.id ? 'multi-work-time-item' : ''
            }`}
          >
            <div className="scheduling-icon-and-label-container">
              <SvgIconComponent
                className={`scheduling-icon task-detail-icon task-detail-icon--active`}
              />
              <p>{getStringRepresationationForTaskData(subTask)}</p>
            </div>
            <div>
              {shouldDisplayReminderBell(reminderValue) && (
                <div
                  id="task-reminder-bell"
                  className={`bell-reminder ${!reminderType && 'visibility-hidden'}`}
                >
                  <BellIconComponent className={bellIconClassName} />
                </div>
              )}
            </div>
          </div>
        )}
      </Fragment>
    );
  };

  const getStringRepresationationForTaskData = (subTask: ISubTask) => {
    if (reminderType === 'dueDate') {
      const date = new Date(task?.dueDate!);
      return `${getDateFormatted(date)} ${getTimeRange(date, null)}`;
    } else {
      let str = '';
      if (subTask.workTime && subTask.duration) {
        const start = new Date(subTask.workTime);
        str = `${getDateFormatted(start)} ${getTimeRange(start, subTask.duration)}`;
      } else if (subTask.workTime) {
        const workTime = new Date(subTask.workTime);
        str = `${getDateFormatted(workTime)} ${getTimeRange(workTime, null)}`;
      } else if (subTask.duration) {
        str = `Unscheduled ${minutesToHoursAndMinutesStringRepresentation(
          Number(subTask.duration) / 60,
        )} Work Time`;
      }
      return str.replaceAll(': ', ' ');
    }
  };

  const handleClickForWorkTimeAction = (e: React.MouseEvent<HTMLElement, any>) => {
    if (
      (reminderType === 'dueDate' && !formValue) ||
      (reminderType === 'workTime' && !formValue && !task.workBlockId)
    )
      onClick(e);
    else {
      handleAddWorkTimePopOver(e);
    }
  };

  const handleAddWorkTimePopOver = (e: React.MouseEvent<HTMLElement, any>, subTask?: ISubTask) => {
    createAppOverlayPopover(
      <TaskEditOverlay
        overlayType={subTask?.workBlockId ? 'taskWorkBlock' : 'workTime'}
        taskWorkBlockDetails={{
          workBlockId: subTask?.workBlockId,
          workBlockInstance: subTask?.workBlockInstance,
        }}
        taskId={subTask?.taskId}
        setCalendarAsTimePicker={setCalendarAsTimePicker}
        isMultiWorkTimeSelector={true}
        duration={subTask?.duration}
        reminder={subTask?.workTimeReminder}
        date={subTask?.workTime ? new Date(subTask.workTime) : undefined}
        recurrenceValues={{
          workTimeRecurrenceType: subTask?.workTimeRecurrenceType || null,
          workTimeRecurrenceInterval: subTask?.workTimeRecurrenceInterval || null,
          workTimeRecurrenceWeekDays: subTask?.workTimeRecurrenceWeekDays || null,
        }}
        onChange={(data, reminder, duration, recurrenceValues) =>
          handleAddOrUpdateWorkTimeToTask(
            data,
            reminder,
            duration,
            subTask?.taskId,
            recurrenceValues,
          )
        }
        onClear={
          subTask?.isMultiWorkTimeTask
            ? () => handleRemoveMultiWorkTimeTask(subTask?.taskId)
            : undefined
        }
      />,
      isSubTask ? subTaskOverlayPopoverClassName : '',
      e,
      isSubTask
        ? {
            ...editOverlaysPositionStyle,
            ...getElementPosition(document.getElementById('add-edit-task-form-container')!),
            ...getElementWidthAndHeight(document.getElementById('add-edit-task-form-container')!),
          }
        : editOverlaysPositionStyle,
      {
        // overlay config
        isCustomStyle: isSubTask,
        slideInMobileAnimation: isSubTask
          ? EAppOverlaySlideInMobileAnimation.FULL_SCREEN
          : EAppOverlaySlideInMobileAnimation.HALF_SCREEN,
        shouldCloseBySwipeOnMobile: true,
        closeSwipeDirection: ECloseSwipeDirection.DOWN,
      },
    );
  };

  const handleAddOrUpdateWorkTimeToTask = (
    data: number | Date | ITaskTag | undefined,
    reminder?: string | number | null | undefined,
    duration?: number | null | undefined,
    subTaskId?: string,
    recurrenceValues?: IRecurrenceValues,
  ) => {
    const currentSessionId = sessionResponse?.data?.sessionId || sessionIdLocalStorage;
    const recurrenceProperties = recurrenceValues ? { ...recurrenceValues } : {};

    const reqPayload = {
      sessionId: currentSessionId,
      parentId: task?.taskId,
      name: null,
      duration: duration,
      workTimeReminder: reminder !== null && reminder !== undefined ? Number(reminder) : null,
      workTime: data ? new Date(data as string).toISOString() : null,
      status: ETaskStatus.NOT_STARTED,
      ...recurrenceProperties,
    };
    if (!subTaskId && appendWorkTimeToTask) {
      !!data &&
        dispatch(createTaskReqAction(reqPayload))
          .unwrap()
          .then((data) => {
            appendWorkTimeToTask({ ...data[0], taskId: data[0].id });
            dispatch(getTasksListReqAction());
          });
    } else {
      const updateRequest = Object.assign({}, reqPayload, { id: subTaskId! });
      dispatch(updateTaskReqAction(updateRequest))
        .unwrap()
        .then((data) => {
          const index = multiWorkTimesSubTasks?.findIndex((t) => t.taskId === subTaskId);
          if (index !== undefined && index !== -1 && updateWorkTimeToTask)
            updateWorkTimeToTask(index, { ...data, taskId: data.id });
          dispatch(getTasksListReqAction());
        })
        .catch((e) => console.error('update work-time sub task failed with the error: ', e));
    }
  };

  const handleRemoveMultiWorkTimeTask = (subTaskId?: string) => {
    if (subTaskId) {
      const currentSessionId = sessionResponse?.data?.sessionId || sessionIdLocalStorage;
      const reqPayload = {
        sessionId: currentSessionId,
        id: subTaskId,
        status: ETaskStatus.DELETED,
      };
      dispatch(updateTaskReqAction(reqPayload))
        .unwrap()
        .then(() => {
          const index = multiWorkTimesSubTasks?.findIndex((t) => t.taskId === subTaskId);
          if (index !== undefined && index !== -1 && removeWorkTimeToTask)
            removeWorkTimeToTask(index);
          dispatch(getTasksListReqAction());
        });
    }
  };

  const shouldDisplayContentDiv = () => {
    if (reminderType === 'workTime') return !!formValue || !!task?.workBlockId;
    else return !!formValue;
  };

  return (
    <section
      className={`scheduling-section task-detail-section ${
        isReadOnly ? 'scheduling-section--read-only' : ''
      }`}
    >
      {controlTitle && <h4 className="create-or-edit-sub-title">{controlTitle}</h4>}
      {shouldDisplayContentDiv() && renderContentForTask(task)}
      {(multiWorkTimesSubTasks || [])?.map((subTask) => renderContentForTask(subTask))}
      {shouldDisplayAddWorkTimeAction() && (
        <div
          onClick={isSubTask ? onClick : handleClickForWorkTimeAction}
          className="scheduling-section-add-data-action-container"
        >
          <SvgIconComponent className={`scheduling-icon task-detail-icon`} />
          <p>{title}</p>
        </div>
      )}
    </section>
  );
};

export default SchedulingSection;
