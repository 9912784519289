import type { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import {
  chatSessionIdLocalStorageKey,
  loginTypeStorageKey,
  operatingSystemLocalStorageKey,
  tokenLocalStorageKey,
} from '../../app/constants';
import { ApplicationInsightsApi } from '../../application-insights';
import { getItemFromLocalStorage } from '../utils/localStorage.utils';
import { EnhancedStore } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

/**
 * Injecting the store to be able to use it inside axios interceptors
 * https://redux.js.org/faq/code-structure#how-can-i-use-the-redux-store-in-non-component-files
 */

// eslint-disable-next-line
let store: EnhancedStore<RootState> | undefined;
export const injectStoreAxiosInterceptors = (_store: EnhancedStore) => {
  store = _store;
};

/**
 * Add auth header with token, depending on server convention you might not need to add the word 'Bearer'
 * @param config axios config
 */
export const addAuthorizationHeaderInterceptor = (config: InternalAxiosRequestConfig) => {
  try {
    const token =
      store?.getState()?.authReducer?.loginRes?.data?.token ||
      getItemFromLocalStorage<string>(tokenLocalStorageKey);
    const loginType = getItemFromLocalStorage<string>(loginTypeStorageKey);
    const userId = store?.getState()?.userReducer?.user?.id || null;
    const sessionId = getItemFromLocalStorage(chatSessionIdLocalStorageKey);
    const operatingSystem = getItemFromLocalStorage<string>(operatingSystemLocalStorageKey);

    if (config?.headers) {
      // if there is a token saved try to use it and set it in the headers in the request.
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
        config.headers['x-auth-provider'] = loginType;
      }
      if (userId) {
        config.headers['x-user-id'] = userId;
      }
      if (sessionId) {
        config.headers['x-session-id'] = sessionId;
      }
      if (operatingSystem) {
        config.headers['x-operating-system'] = operatingSystem;
      }
      config.headers['x-client-version'] = process.env.REACT_APP_BUILD_VERSION;
      config.headers['x-client-build-number'] = process.env.REACT_APP_BUILD_NUMBER;
    }
    return config;
  } catch (error) {
    ApplicationInsightsApi.trackException(error);
    console.error(error);
    return Promise.reject(error);
  }
};

export const addApplicationInsightsTraces = (config: InternalAxiosRequestConfig) => {
  try {
    const eventData = {
      requestBody: { ...config?.data },
      requestParams: { ...config?.params },
      userId: store?.getState()?.userReducer?.user?.id || 'userId_not_available',
      sessionId: getItemFromLocalStorage(chatSessionIdLocalStorageKey) || 'sessionId_not_available',
      baseURL: config?.baseURL,
    };
    ApplicationInsightsApi.trackTrace(`Client app - ${config?.method} - ${config?.url}`, eventData);
    return config;
  } catch (error) {
    ApplicationInsightsApi.trackException(error);
    return Promise.reject(error);
  }
};

export const rejectionApplicationInsightTraces = (error: any) => {
  try {
    const eventData = {
      error: { ...error },
      userId: store?.getState()?.userReducer?.user?.id || 'userId_not_available',
      sessionId: getItemFromLocalStorage(chatSessionIdLocalStorageKey) || 'sessionId_not_available',
    };
    ApplicationInsightsApi.trackTrace(
      `Client app error - rejectionApplicationInsightTraces`,
      eventData,
    );
    return Promise.reject(error);
  } catch (error) {
    ApplicationInsightsApi.trackException(error);
    return Promise.reject(error);
  }
};

export const updatePendingIndicationToWindowOnRequest = (config: InternalAxiosRequestConfig) => {
  try {
    (window as any).isAxiosPending = true;
    return config;
  } catch (error) {
    (window as any).isAxiosPending = false;
    return Promise.reject(error);
  }
};

export const updatePendingIndicationToWindowOnResponse = (response: AxiosResponse<any, any>) => {
  try {
    (window as any).isAxiosPending = false;
    return response;
  } catch (error) {
    (window as any).isAxiosPending = false;
    ApplicationInsightsApi.trackException(error);
    return Promise.reject(error);
  }
};

export const rejectionUpdatePendingIndicationToWindow = (error: any) => {
  try {
    (window as any).isAxiosPending = false;
    ApplicationInsightsApi.trackTrace(
      `Client app error - rejectionUpdatePendingIndicationToWindow`,
      error,
    );
    return Promise.reject(error);
  } catch (error) {
    (window as any).isAxiosPending = false;
    return Promise.reject(error);
  }
};
