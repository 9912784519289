import type { FunctionComponent } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  chatHistoryParam,
  productTourDataAttrUserAvatarMobile,
  settingsMenuParam,
} from '../../../../../app/constants';
import backArrowIcon from '../../../../../assets/images/circle_arrow_back.svg';
import { Avatar } from '../../../avatar/Avatar';
import { getInitials } from '../../../../utils/getInitials';
import './UserAvatarHeader.scss';
import { useAppSelector } from '../../../../../app/store';

export const UserAvatarHeader: FunctionComponent = () => {
  const { user: userInfo } = useAppSelector((store) => store.userReducer);
  const [searchParams, setSearchParams] = useSearchParams();

  const onToggleSettingsMenu = () => {
    if (searchParams.get(settingsMenuParam)) {
      setSearchParams((prev) => {
        prev.delete(settingsMenuParam);
        return prev;
      });
    } else {
      setSearchParams((prev) => {
        prev.append(settingsMenuParam, 'open');
        return prev;
      });
    }
  };

  return (
    <div
      className={`open-close-menu-btn-container ${
        searchParams.get(chatHistoryParam) ? 'visibility-hidden' : ''
      }`}
      onClick={onToggleSettingsMenu}
    >
      <button
        id="header-close-settings"
        className={`close-popup-icon close-settings ${
          searchParams.get(settingsMenuParam) ? 'fadeIn' : 'fadeOut'
        }`}
        data-testid={`close-settings`}
      >
        <img src={backArrowIcon} alt="close-settings" />
      </button>
      <button
        id="open-settings"
        data-product-tour={productTourDataAttrUserAvatarMobile}
        className={`avatar-button ${!searchParams.get(settingsMenuParam) ? 'fadeIn' : 'fadeOut'}`}
        data-testid={`user-avatar`}
      >
        <Avatar
          className="header-avatar fadeIn"
          defaultAvatarClassName="default-header-avatar"
          size={32}
          avatarImg={userInfo?.profileImage || ''}
          defaultContent={getInitials(userInfo?.name || userInfo?.email || null)}
        />
      </button>
    </div>
  );
};
